import axios from "axios";

import Config from "./Config";
import SettingsService from "./SettingsService";
import Memory from "./MemoryService";
import APIHelper from "./APIHelper";
import PartnerService from "./PartnerService";

let cancels = {};
const BrandService = {
    typeahead(search) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        return axios
            .post(Config.API_URL + "brand/search", {
                name: search,
                pdlt: SettingsService.Get('pdlt', true)
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },
    dailyVolume(id, website) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/daily/" + website)
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    merchants(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/merchants")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    getSellingWebsites(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/websites")
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    troubleshoot(id, websites) {
        return axios
            .post(Config.API_URL + "brand/" + id + "/troubleshoot", { websites })
            .then(response => APIHelper.returnAttribute(response, 'websites'))
            .catch(APIHelper.returnFalse);
    },
    summary(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/summary")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    products(id, extended = false) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/products" + (extended ? "/extended" : ''))
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    trends(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/trends")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    categories(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/categories")
            .then(response => APIHelper.returnAttribute(response, 'categories'))
            .catch(APIHelper.returnFalse);
    },
    productsDiscovery(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/discovery")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    costs(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/costs")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    metrics(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/metrics")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    violations(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/violations")
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    unblock(brand_id, website_ids) {
        return axios
            .post(Config.API_URL + "brand/" + brand_id + "/release", {
                website_id: website_ids
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Retrieves and stores the Brand name in memory for rendering the Breadcrumbs of /brands
     * @param {int} id
     * @returns
     */
    breadcrumb(id) {
        return new Promise((resolve, reject) => {
            if (Memory.Has('brand/' + id)) {
                resolve(Memory.Get('brand/' + id));
            } else {
                axios
                    .get(Config.API_URL + "brand/" + id + "/name")
                    .then((response) => {
                        if(APIHelper.checkStatus(response)) {
                            Memory.Set('brand/' + id, response.data);
                            resolve(response.data);
                        }
                        reject();
                    })
                    .catch((error) => {
                        reject();
                    });
            }
        });
    },
    all() {
        return axios
            .get(Config.API_URL + "brand")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    get(id) {
        return axios
            .get(Config.API_URL + "brand/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    restrictions(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/restrictions")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    addRestriction(id, website_id, mode) {
        return axios
            .put(Config.API_URL + "brand/" + id + "/restrictions/" + website_id, { mode })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    addRestrictions(id, websites, mode) {
        return axios
            .put(Config.API_URL + "brand/" + id + "/restrictions", { mode, websites })
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    removeRestriction(id, website_id) {
        return axios
            .delete(Config.API_URL + "brand/" + id + "/restrictions/" + website_id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    users(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/users")
            .then(response => APIHelper.returnAttribute(response, 'users'))
            .catch(APIHelper.returnFalse);
    },
    update(id, changes) {
        return axios
            .put(Config.API_URL + "brand/" + id, changes)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    getPillars() {
        return [
            {
                code: "visibility_pillar",
                label: "Visibility",
                modules: [
                    {
                        code: "market_visibility_module",
                        book: "store",
                        label: "Market Visibility",
                        type: "boolean",
                        settings: [
                            {
                                code: "whack_price_protection",
                                book: "store",
                                label: "Use Whack Price Protection",
                                type: "boolean",
                            },
                            {
                                code: "gs-3rd-party-urls",
                                book: "store",
                                label: "Google Shopping - Add 3rd party retailers",
                                type: "boolean",
                            },
                        ]
                    },
                    {
                        code: "amazon_reporting_module",
                        book: "store",
                        label: "Marketplace Analytics",
                        type: "boolean",
                    },
                    {
                        code: "review_tracking_module",
                        book: "store",
                        label: "Product Review Tracking",
                        type: "boolean",
                    },
                    {
                        code: "competitor_insights_module",
                        book: "store",
                        label: "Competitor Intelligence",
                        type: "boolean",
                    },
                    {
                        code: "market_impact_suite",
                        book: "store",
                        label: "Market Impact",
                        type: "boolean",
                    },
                    {
                        code: "amazon_visibility_suite",
                        book: "store",
                        label: "Amazon Insights",
                        type: "boolean",
                        confirm: {
                            on: {
                                title: "CAUTION: HIGH COST ACTION AHEAD",
                                message: "You are about to activate the Amazon Insights feature. " +
                                    "This action is NOT reversible and leads to significant data processing " +
                                    "costs for our company. This feature should ONLY be activated if included " +
                                    "in a signed contract. Please confirm that you are certain that Amazon " +
                                    "Insights is in a signed contract before proceeding. Are you absolutely " +
                                    "certain you want to proceed?",
                                yes: "Yes, I've confirmed the contract includes it",
                                no: "No, I need to double-check",
                                size: "lg",
                            },
                        },
                        feedback: {
                            on: "This setting will trigger the platform to process an entire year of data for this " +
                                "brand, which is expensive. Please make sure this should be enabled before clicking" +
                                "Save because this action is logged and if you enable it by mistake, it will be" +
                                "known.",
                        },
                        settings: []
                    },
                    {
                        code: "walmart_visibility_suite",
                        book: "store",
                        label: "Walmart Visibility",
                        type: "boolean",
                    },
                    {
                        code: "expansion_module",
                        book: "store",
                        label: "Expansion",
                        type: "boolean",
                        settings: [
                            {
                                code: "out_of_stock_module",
                                book: "store",
                                label: "Out Of Stock",
                                type: "boolean",
                            },
                            {
                                code: "whitespace_module",
                                book: "store",
                                label: "White Space",
                                type: "boolean",
                            },
                        ]
                    },
                    {
                        code: "extended_pricing_module",
                        book: "store",
                        label: "Extended Pricing",
                        type: "boolean",
                    },
                ],
            },
            {
                code: "enforcement_pillar",
                label: "Enforcement",
                modules: [
                    {
                        code: "enforcement_module",
                        book: "store",
                        label: "Enforcement",
                        type: "boolean",
                        settings: [
                            {
                                code: "price_enforcement",
                                book: "store",
                                label: "Price Enforcement",
                                type: "boolean",
                            },
                            {
                                code: "unauthorized_seller_enforcement",
                                book: "store",
                                label: "Unauthorized Seller Enforcement",
                                type: "boolean",
                            },
                            {
                                code: "on_demand_notifications",
                                book: "store",
                                label: "On Demand Notifications",
                                type: "boolean",
                            },
                            {
                                code: "enforcement_type",
                                book: "store",
                                label: "Enforcement Type",
                                type: "option",
                                options: [
                                    {
                                        label: "Per Merchant",
                                        value: "merchant"
                                    },
                                    {
                                        label: "Per Product",
                                        value: "product"
                                    },
                                    {
                                        label: "Per Master Product",
                                        value: "master_product"
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        code: "price_compliance_suite",
                        book: "store",
                        label: "Price Compliance",
                        type: "boolean",
                    },
                    {
                        code: "unauthorized_compliance_suite",
                        book: "store",
                        label: "Unauthorized Compliance",
                        type: "boolean",
                    },
                    {
                        code: "rolled_up_reporting_suite",
                        book: "store",
                        label: "Rolled-Up Reporting",
                        type: "boolean",
                    },
                ],
            },
            {
                code: "connectivity_pillar",
                label: "Connectivity",
                modules: [
                    {
                        code: "merchant_portal_module",
                        book: "store",
                        label: "Partner Portal",
                        type: "boolean",
                    },
                    {
                        code: PartnerService.WHERE_TO_BUY_MODULE_NAME,
                        book: "store",
                        label: "Where to Buy",
                        type: "boolean",
                    },
                    {
                        code: "authorized_sellers_module",
                        book: "store",
                        label: "Merchant Badges",
                        type: "boolean",
                    },
                ],
            },
        ];
    },
    new(name) {
        return axios
            .post(Config.API_URL + "brand", {
                name
            })
            .then(response => APIHelper.returnAttribute(response, 'id'))
            .catch(APIHelper.returnFalse);
    },
    getSellingCountries(id) {
        return axios
            .get(Config.API_URL + "brand/" + id + "/countries")
            .then(response => APIHelper.returnAttribute(response, 'countries'))
            .catch(APIHelper.returnFalse);
    }
};

export default BrandService;
